import React from "react";
import {ReportParams, ReportView} from "../../../system/ReportBase";
import {getDailyStrategyPNLReports} from "../../../apis/vitusApi";
import {IDailyStrategyPNLReport} from "../../../apis/vitusApiTypes";
import {handleApiError} from "../../../utils/common";
import {createSpinner} from "../../../utils/spinnerManager";
import ReportViewer from "../../ReportViewer/ReportViewer";
import VPageFilter from "../../../components/VPageFilter/VPageFilter";
import {Grid} from "@material-ui/core";
import VSelect from "../../../components/VSelect/VSelect";
import VTable from "../../../components/VTable/VTable";
import AlertManager from "../../../utils/alertManager";
import messages from "../../../utils/messages";
import VDatePicker from "../../../components/VDatePicker/VDatePicker";

interface IFilter {
    User?: string;
    Exchange?: string;
    SymbolId?: string;
    MinDate?: Date;
    MaxDate?: Date;
}

interface IState {
    selectedFilter: IFilter;
    data: IDailyStrategyPNLReport[];
    filterIsHidden: boolean;
}

const defaultFilter: IFilter = {
    User: "",
    Exchange: "",
    SymbolId: "",
    MinDate: new Date(new Date().setDate(new Date().getDate() - 7)),
}

type Column<T> = {
    key: keyof T;
    label: string;
    format?: (value: any) => any;
};

const fetchData = async (filter: IFilter, setData: (data: IDailyStrategyPNLReport[]) => void, onError: (error: any) => void) => {
    const loadingSpinner = createSpinner();
    try {
        const response = await getDailyStrategyPNLReports(filter);
        if (response.data.success) {
            setData(response.data.success);
        }
        if (response.data.error) {
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED)
        }
    } catch (error) {
        onError(error);
    } finally {
        loadingSpinner.hide();
    }
};

const formatTableData = (data: IDailyStrategyPNLReport[], columns: Array<Column<IDailyStrategyPNLReport>>) => {
    return data.map((row) => {
        return columns.reduce((acc, col) => {
            acc[col.label] = col.format ? col.format(row[col.key]) : row[col.key];
            return acc;
        }, {} as Record<string, string | number>);
    });
}

class DailyStrategyPNLReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "DAILY_STRATEGY_PNL_REPORT",
            name: "Daily Strategy PNL Report",
            path: '/dailyStrategyPNLReport',
            thumbnail: ""
        }
    )

    state: IState = {
        selectedFilter: defaultFilter,
        data: [],
        filterIsHidden: false
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        fetchData(this.state.selectedFilter, (data) => {
            this.setState({ data });
        }, handleApiError);
    }

    renderFilter = () => {
        const filters = [
            { title: 'User', key: 'User' },
            { title: 'Exchange', key: 'Exchange' },
            { title: 'Symbol ID', key: 'SymbolId' }
        ];

        const renderSelect = (title: string, key: keyof IFilter) => (
            <Grid item xs={2} key={key}>
                <VSelect
                    title={title}
                    options={Array.from(new Set(this.state.data.map(dailyReport => dailyReport[key as keyof IDailyStrategyPNLReport])))}
                    value={this.state.selectedFilter[key] || ''}
                    getOptionLabel={(option) => option.toString()}
                    onChange={(newValue) => this.setState((prevState) => ({
                        selectedFilter: { ...prevState.selectedFilter, [key]: newValue }
                    }), this.fetchData)}
                    width="150px"
                />
            </Grid>
        );

        return (
            <VPageFilter initialShow>
                <Grid container justify="flex-start" alignItems="flex-start" xs={6}>
                    {filters.map(({ title, key }) => renderSelect(title, key as keyof IFilter))}
                    <Grid item xs={2} style={{ minWidth: '14%' }}>
                        <VDatePicker
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label="Min DateTime"
                            maxDate={this.state.selectedFilter.MaxDate}
                            value={this.state.selectedFilter.MinDate || null}
                            onChange={(date) => {
                                this.setState({
                                    selectedFilter: { ...this.state.selectedFilter, MinDate: date?.toDate() }
                                }, this.fetchData)
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ minWidth: '14%' }}>
                        <VDatePicker
                            variant="inline"
                            format="DD/MM/yyyy"
                            margin="normal"
                            label="Max DateTime"
                            minDate={this.state.selectedFilter.MinDate}
                            value={this.state.selectedFilter.MaxDate || null}
                            onChange={(date) => {
                                this.setState({
                                    selectedFilter: { ...this.state.selectedFilter, MaxDate: date?.toDate() }
                                }, this.fetchData)
                            }}
                        />
                    </Grid>
                </Grid>
            </VPageFilter>
        );
    }

    render() {
        const columns: Array<Column<IDailyStrategyPNLReport>> = [
            { key: "Date", label: "Date", format: (value) => new Date(value).toLocaleDateString() },
            { key: "Exchange", label: "Exchange" },
            { key: "SymbolId", label: "SymbolId" },
            { key: "User", label: "User" },
            { key: "PnLDaily", label: "PnL Daily" },
            { key: "LongDaily", label: "Long Daily" },
            { key: "BWAPDaily", label: "BWAP Daily" },
            { key: "SWAPDaily", label: "SWAP Daily" },
            { key: "PnLTotal", label: "PnL Total" },
            { key: "LongTotal", label: "Long Total" },
            { key: "ShortTotal", label: "Short Total" },
            { key: "BWAPTotal", label: "BWAP Total" },
            { key: "SWAPTotal", label: "SWAP Total" },
            { key: "SettlementPrice", label: "Settlement Price" },
            { key: "PnlCarryDaily", label: "Pnl Carry Daily" },
            { key: "PnlCarryTotal", label: "PnL Carry Total" }
        ];

        const tableData = formatTableData(this.state.data, columns);

        return (
            <ReportViewer {...DailyStrategyPNLReport.params} onRefresh={() => this.fetchData()}>
                <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>{this.renderFilter()}</Grid>
                    <Grid item xs={12}>
                        <VTable
                            makeFirstColumnsSticky
                            tableData={{
                                columns: columns.map(col => col.label),
                                data: tableData
                            }}
                            keyFields={["Date", "Exchange", "SymbolId", "User"]}
                        />
                    </Grid>
                </Grid>
            </ReportViewer>
        );
    }
}

export default DailyStrategyPNLReport;